.container {
  height: 100vh;
  width: 100vw;
  background: no-repeat center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.zaBg {
  background-image: url(../assets/za.webp);
}
.wotlkBg {
  background-image: url(../assets/wotlk.webp);
}
.timers {
  width: min(800px, 90%);
  z-index: 9999;
  margin: 0 20px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  place-items: center;
  column-gap: 2rem;
}

.time {
  font-size: 6rem;
  font-weight: 900;
  color: var(--green);
  margin: 0;
}
.zaFirstRow {
  color: var(--green);
}
.header {
  font-weight: 400;
  color: var(--red);
  font-size: 2rem;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .timers {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .daysHeader {
    grid-row: 1;
  }
  .hoursHeader {
    grid-row: 2;
  }
  .minutesHeader {
    grid-row: 3;
  }
  .secondsHeader {
    grid-row: 4;
  }
}
@keyframes roundtime {
  to {
    /* More performant than animating `width` */
    transform: scaleX(0);
  }
}
