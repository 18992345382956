.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: min(90%, 1200px);
  min-height: 80vh;
}
.card {
  box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.2);
  font-size: 2rem;
  font-weight: bold;
  border-radius: 0.2rem;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.disabledCard {
  position: relative;
  z-index: 1;
}
.disabledBanner {
  position: absolute;
  opacity: 5;
  z-index: 999;
  top: 5px;
  right: 5px;
  transform: rotate(45deg);
}
.title {
  font-size: 2.25rem;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.link {
  text-decoration: none;
  width: min(90%, 400px);
}
.disabledLink {
  pointer-events: none;
  opacity: 0.3;
}
.wotlk {
  background-image: url(../assets/wotlk.webp);
  -webkit-text-stroke: 1px var(--super-light-blue);
}
.za {
  background-image: url(../assets/za.webp);
  -webkit-text-stroke: 1px var(--orange);
}
