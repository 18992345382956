@import url("https://fonts.googleapis.com/css2?family=Koulen&family=Sintony:wght@400;700&display=swap");

:root {
  --black: #141e27;
  --red: #f15d58;
  --super-light-blue: #b2d5f9;
  --orange: #ef9d71;
  --light-red: #e4a9a8;
  --bg-dark: #202125;
  --green: #1fb180;
  --font-default: Koulen, sans-serif;
}
body {
  margin: 0;
  min-height: 100vh;
  background-color: var(--bg-dark);
  font-family: Koulen, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
